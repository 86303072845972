import { FC, useEffect, useState } from 'react';
import css from './static-group-devices.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { StaticGroupForm } from './components/static-group-form/static-group-form.component';
import {
  getStaticGroup,
  createStaticGroup,
  deleteStaticGroup,
  updateStaticGroup
} from '../../../api/static-groups-devices';
import {
  CreateStaticGroupDevicesRequestDto,
  DeviceOsType,
  Permission,
  StaticGroupDevicesFullDto,
  UpdateStaticGroupDevicesRequestDto
} from '../../../types/api';
import { getLocalizedErrorString } from '../../../utils/localize-error';
import useDeviceSection, { DeviceType } from '../../contexts/device-section.context';
import { Header } from '../../components/header/header.component';
import { ActionMenu } from './components/action-menu/action-menu.component';
import { Dialog, Popover, Text, TextInput, useToaster } from '@gravity-ui/uikit';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../hooks/useRequest';
import { StaticGroupFormSkeleton } from './components/skeleton/skeleton.component';
import { useForm } from 'react-hook-form';
import {
  staticGroupFormSchema,
  StaticGroupFormValues
} from './components/static-group-form/static-group-form.schema';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { usePermission } from '../../contexts/permission.context';

export enum StaticGroupFormMode {
  CREATE = 'create',
  UPDATE = 'update',
  VIEW = 'view'
}

export const StaticGroupDevices: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const toaster = useToaster();
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const { deviceType } = useDeviceSection();
  const osType = deviceType === DeviceType.COMPUTERS ? DeviceOsType.MacOS : DeviceOsType.IOS;
  const crudRequest = useRequest();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [staticGroup, setStaticGroup] = useState<StaticGroupDevicesFullDto>();
  const [mode, setMode] = useState<StaticGroupFormMode>(
    id === 'new' ? StaticGroupFormMode.CREATE : StaticGroupFormMode.VIEW
  );

  const staticGroupForm = useForm<StaticGroupFormValues>({
    mode: 'onChange',
    resolver: yupResolver(staticGroupFormSchema),
    defaultValues: staticGroupFormSchema.getDefault()
  });
  const {
    trigger,
    getValues,
    reset,
    register,
    formState: { errors }
  } = staticGroupForm;

  const handleStaticGroupCreate = async () => {
    if (crudRequest.loading || !deviceType) return;

    const isValid = await trigger();
    if (!isValid) return;

    try {
      const request: CreateStaticGroupDevicesRequestDto = {
        title: getValues().general.display_name,
        devices: getValues().devices.map((device) => device.id),
        os_type: osType
      };
      const response = (await crudRequest.send(
        createStaticGroup(request),
        1000
      )) as StaticGroupDevicesFullDto;
      setStaticGroup(response);

      setMode(StaticGroupFormMode.VIEW);
      navigate(`./../${response.id}`);
      toaster.add({
        name: 'create-success',
        content: t('static_groups.page.successfully_created', { group: response.title }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toaster.add({
        name: 'create-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };
  const handleStaticGroupCreateCancel = () => navigate('./../');
  const handleStaticGroupEditStart = () => setMode(StaticGroupFormMode.UPDATE);
  const handleStaticGroupEdit = async () => {
    if (crudRequest.loading || !id) return;

    const isValid = await trigger();
    if (!isValid) return;

    try {
      const request: UpdateStaticGroupDevicesRequestDto = {
        title: getValues().general.display_name,
        devices: getValues().devices.map((device) => device.id)
      };
      const response = (await crudRequest.send(
        updateStaticGroup(request, id),
        1000
      )) as StaticGroupDevicesFullDto;
      setStaticGroup(response);

      setMode(StaticGroupFormMode.VIEW);
      toaster.add({
        name: 'edit-success',
        content: t('static_groups.page.successfully_updated', { group: response.title }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toaster.add({
        name: 'edit-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };
  const handleStaticGroupEditCancel = () => setMode(StaticGroupFormMode.VIEW);
  const handleStaticGroupDelete = () => setIsDeleteModalOpen(true);

  const handleDeleteModalSubmit = async () => {
    if (crudRequest.loading || !id) return;

    try {
      await crudRequest.send(deleteStaticGroup(id), 1000);
      navigate('./..');
      toaster.add({
        name: 'delete-success',
        content: t('static_groups.page.successfully_deleted', {
          group: staticGroup?.title
        }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toaster.add({
        name: 'delete-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };
  const handleDeleteModalClose = () => setIsDeleteModalOpen(false);

  useEffect(() => {
    const init = async () => {
      if (!id) return;
      if (id !== 'new') {
        const response = (await crudRequest.send(
          getStaticGroup(id),
          1000
        )) as StaticGroupDevicesFullDto;
        setStaticGroup(response);
        reset({
          general: { display_name: response.title },
          devices: response.devices
        });
        setMode(StaticGroupFormMode.VIEW);
      }
    };
    void init();
  }, []);

  return (
    <>
      <Header
        breadcrumbsTitle={staticGroup?.title}
        rightContent={
          <ActionMenu
            mode={mode}
            disabled={crudRequest.loading || isAllowedTo(Permission.EditGroups)}
            onStaticGroupCreate={handleStaticGroupCreate}
            onStaticGroupCreateCancel={handleStaticGroupCreateCancel}
            onStaticGroupDelete={handleStaticGroupDelete}
            onStaticGroupEdit={handleStaticGroupEdit}
            onStaticGroupEditStart={handleStaticGroupEditStart}
            onStaticGroupEditCancel={handleStaticGroupEditCancel}
          />
        }
      />
      <div className={css.Content}>
        {!crudRequest.loading ? (
          <>
            <div className={css.Title}>
              {mode === 'view' && <Text variant="display-2">{staticGroup?.title}</Text>}
              {mode !== 'view' && (
                <Popover content={t('common.click_to_edit')}>
                  <TextInput
                    placeholder={t('static_groups.page.group_information_tab.display_name')}
                    size="xl"
                    view="clear"
                    error={errors.general?.display_name?.message}
                    errorPlacement="inside"
                    controlProps={{ className: css.TitleInput }}
                    {...register('general.display_name')}
                  />
                </Popover>
              )}
            </div>
            <StaticGroupForm mode={mode} form={staticGroupForm} />
          </>
        ) : (
          <StaticGroupFormSkeleton />
        )}
      </div>
      <Dialog onClose={handleDeleteModalClose} open={isDeleteModalOpen}>
        <Dialog.Header caption={t('static_groups.page.modal_delete.title')} />
        <Dialog.Body>
          <Text>
            {t('static_groups.page.modal_delete.message', {
              title: staticGroup?.title
            })}
          </Text>
        </Dialog.Body>
        <Dialog.Footer
          textButtonApply={t('static_groups.page.modal_delete.delete_btn')}
          textButtonCancel={t('common.modal.cancel_btn')}
          onClickButtonApply={handleDeleteModalSubmit}
          onClickButtonCancel={handleDeleteModalClose}
          loading={crudRequest.loading}
        />
      </Dialog>
    </>
  );
};
