import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AddDeviceApplicationDto,
  DeviceApplicationDto,
  Permission,
  SearchDeviceApplication
} from '../../../types/api';
import { MobileDeviceAppAssistant } from './components/device-application-assistant/device-application-assistant.component';
import { getLocalizedErrorString } from '../../../utils/localize-error';
import { deviceApplicationSchema, DeviceApplicationFormValues } from './device-application.schema';
import {
  createDeviceApplication,
  deleteDeviceApplication,
  getDeviceApplication,
  updateDeviceApplication
} from '../../../api/device-applications';
import { DeviceApplicationForm } from './components/device-application-form/device-application-form.component';
import useRequest from '../../../hooks/useRequest';
import { useTranslation } from 'react-i18next';
import { appToForm, formScopeEntries } from './device-application.utils';
import { Dialog, Text, useToaster } from '@gravity-ui/uikit';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import css from './device-application.module.scss';
import { Header } from '../../components/header/header.component';
import { ActionMenu } from './components/action-menu/action-menu.component';
import { FormSkeleton } from './components/form-skeleton/form-skeleton.component';
import { usePermission } from '../../contexts/permission.context';

export const DeviceApplication: FC = () => {
  const { id } = useParams();

  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const navigate = useNavigate();
  const toaster = useToaster();
  const crudRequest = useRequest<DeviceApplicationDto>();
  const appRef = useRef<DeviceApplicationDto>();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [formMode, setFormMode] = useState<'view' | 'create' | 'add' | 'edit'>(
    id === 'new' ? 'add' : 'view'
  );

  const appForm = useForm<DeviceApplicationFormValues>({
    mode: 'onChange',
    resolver: yupResolver(deviceApplicationSchema),
    defaultValues: deviceApplicationSchema.getDefault()
  });

  const handleEdit = () => setFormMode('edit');
  const handleCancel = async () => {
    appForm.reset();
    if (formMode === 'create') {
      navigate('./../');
      return;
    }
    setFormMode('view');
  };
  const handleDelete = async () => {
    if (!id) return;
    try {
      const response = await crudRequest.send(deleteDeviceApplication(id));

      navigate('./../');
      toaster.add({
        name: 'delete-device-app-success',
        content: t('device_apps.app_form.responses.successfully_deleted', {
          display_name: response.display_name
        }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (e) {
      const localizedError = getLocalizedErrorString(e as Error);
      toaster.add({
        name: 'delete-device-app-error',
        content: localizedError,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };
  const handleSave = async () => {
    if (crudRequest.loading || !appRef.current) return;

    const isValid = await appForm.trigger();
    if (!isValid) return;

    const values = appForm.getValues();
    const request = {
      ...(values.display_name && { display_name: values.display_name }),
      ...(values.bundle_id && { bundle_id: values.bundle_id }),
      ...(values.app_view_url && { app_view_url: values.app_view_url }),
      ...(values.version && { version: values.version }),
      scoped: values.scope?.scoped,
      ...formScopeEntries(values.scope)
    };
    try {
      const response = await crudRequest.send(updateDeviceApplication(appRef.current?.id, request));
      appForm.reset(appToForm(response));
      setFormMode('view');

      navigate(`./../${response.id}`);
      toaster.add({
        name: 'save-device-app-success',
        content: t('device_apps.app_form.responses.successfully_updated', {
          display_name: response.display_name
        }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedError = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'save-device-app-error',
        content: localizedError,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };
  const handleCreate = async () => {
    if (crudRequest.loading) return;

    const isValid = await appForm.trigger();
    if (!isValid) return;

    const values = appForm.getValues();
    const request: AddDeviceApplicationDto = {
      display_name: values.display_name,
      bundle_id: values.bundle_id,
      app_view_url: values.app_view_url,
      source: 'App Store',
      version: values.version,
      free: true,
      scoped: values.scope?.scoped,
      ...formScopeEntries(values.scope)
    };

    try {
      const response = await crudRequest.send(createDeviceApplication(request));
      appForm.reset(response);
      appRef.current = response;

      setFormMode('view');
      navigate(`./../${response.id}`);
      toaster.add({
        name: 'create-device-app-success',
        content: t('device_apps.app_form.responses.successfully_created', {
          display_name: response.display_name
        }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedError = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'create-device-app-error',
        content: localizedError,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleDeleteModalOpen = () => setIsDeleteModalOpen(true);
  const handleDeleteModalClose = () => setIsDeleteModalOpen(false);

  const handleAddApp = async (row: SearchDeviceApplication) => {
    const dto: DeviceApplicationFormValues = {
      display_name: row.trackName,
      bundle_id: row.bundleId,
      free: row.price === 0,
      app_view_url: row.trackViewUrl,
      version: row.version,
      source: 'App Store'
    };
    appForm.reset(dto);
    setFormMode('create');
  };

  const handleCreateManually = () => {
    setFormMode('create');
  };

  useEffect(() => {
    const init = async () => {
      if (formMode === 'create' || formMode === 'add' || !id) return;
      try {
        const response = await crudRequest.send(getDeviceApplication(id));
        appRef.current = response;
        appForm.reset(appToForm(response));
      } catch (error) {
        const localizedError = getLocalizedErrorString(error as Error);
        toaster.add({
          name: 'fetch-device-app-error',
          content: localizedError,
          theme: 'danger',
          autoHiding: 5000
        });
      }
    };
    void init();
  }, [id, formMode]);

  return (
    <div className={css.Root}>
      <Header
        breadcrumbsTitle={
          formMode === 'create' ? t('device_apps.app_list.new_app') : appRef.current?.display_name
        }
        rightContent={
          <ActionMenu
            mode={formMode}
            disabled={crudRequest.loading || !isAllowedTo(Permission.EditDeviceApplications)}
            onDeviceAppCreate={handleCreate}
            onDeviceAppCancel={handleCancel}
            onDeviceAppEditStart={handleEdit}
            onDeviceAppEdit={handleSave}
            onDeviceAppDelete={handleDeleteModalOpen}
          />
        }
      />
      {formMode !== 'add' && crudRequest.loading && (
        <div className={css.Content}>
          <FormSkeleton />
        </div>
      )}
      {formMode !== 'add' && !crudRequest.loading && (
        <div className={css.Content}>
          <div className={css.Title}>
            <Text variant="display-2">
              {formMode === 'create'
                ? t('device_apps.app_list.new_app')
                : appRef.current?.display_name}
            </Text>
          </div>
          <FormProvider {...appForm}>
            <DeviceApplicationForm disabled={formMode === 'view' || crudRequest.loading} />
          </FormProvider>
        </div>
      )}
      {formMode == 'add' && (
        <MobileDeviceAppAssistant
          onAddApp={handleAddApp}
          onCreateAppManually={handleCreateManually}
        />
      )}
      <Dialog onClose={handleDeleteModalClose} open={isDeleteModalOpen}>
        <Dialog.Header
          caption={t('device_apps.app_form.modal_delete.title', {
            title: appRef.current?.display_name
          })}
        />
        <Dialog.Body>
          <Text>
            {t('device_apps.app_form.modal_delete.message', {
              title: appRef.current?.display_name
            })}
          </Text>
        </Dialog.Body>
        <Dialog.Footer
          onClickButtonApply={handleDelete}
          onClickButtonCancel={handleDeleteModalClose}
          textButtonApply={t('device_apps.app_form.modal_delete.delete_btn')}
          textButtonCancel={t('common.modal.cancel_btn')}
        />
      </Dialog>
    </div>
  );
};
