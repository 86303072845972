import { FC, FormEvent } from 'react';
import css from './device-user-form.module.scss';
import { Permission } from '../../../../../types/api';
import { usePermission } from '../../../../contexts/permission.context';
import { useTranslation } from 'react-i18next';
import { DeviceUserFormValues } from './device-user-form.schema';
import { UseFormReturn } from 'react-hook-form';
import { Text, TextInput } from '@gravity-ui/uikit';

interface IProps {
  mode: 'view' | 'create' | 'edit';
  form: UseFormReturn<DeviceUserFormValues>;
}

export const DeviceUsersForm: FC<IProps> = (props) => {
  const { mode, form } = props;
  const {
    register,
    formState: { errors }
  } = form;

  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <fieldset className={css.Fieldset} disabled={!isAllowedTo(Permission.EditDeviceUsers)}>
        <div>
          <Text>{t('device_users.user_form.column_email')}</Text>
          <TextInput
            errorMessage={errors.email?.message}
            {...register('email')}
            disabled={mode === 'view'}
          />
        </div>
        <div>
          <Text>{t('device_users.user_form.column_employee_name')}</Text>
          <TextInput
            errorMessage={errors.employee_name?.message}
            {...register('employee_name')}
            disabled={mode === 'view'}
          />
        </div>
        <div>
          <Text>{t('device_users.user_form.column_phone_number')}</Text>
          <TextInput
            errorMessage={errors.phone_number?.message}
            {...register('phone_number')}
            type="tel"
            controlProps={{
              pattern: '[0-9]{3}-[0-9]{3}-[0-9]{4}'
            }}
            disabled={mode === 'view'}
          />
        </div>
        <div>
          <Text>{t('device_users.user_form.column_position')}</Text>
          <TextInput
            errorMessage={errors.position?.message}
            {...register('position')}
            disabled={mode === 'view'}
          />
        </div>
      </fieldset>
    </form>
  );
};
