import { FC, useEffect, useState } from 'react';
import css from './static-group-device-users.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { StaticGroupForm } from './components/static-group-form/static-group-form.component';
import {
  CreateStaticGroupDeviceUsersRequestDto,
  Permission,
  StaticGroupDeviceUsersFullDto,
  UpdateStaticGroupDeviceUsersRequestDto
} from '../../../types/api';
import { getLocalizedErrorString } from '../../../utils/localize-error';
import {
  createStaticGroup,
  deleteStaticGroup,
  getStaticGroup,
  updateStaticGroup
} from '../../../api/static-groups-device-users';
import { useTranslation } from 'react-i18next';
import { Dialog, Popover, Text, TextInput, useToaster } from '@gravity-ui/uikit';
import { Header } from '../../components/header/header.component';
import { ActionMenu } from './components/action-menu/action-menu.component';
import useRequest from '../../../hooks/useRequest';
import { StaticGroupFormSkeleton } from './components/skeleton/skeleton.component';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  staticGroupFormSchema,
  StaticGroupFormValues
} from './components/static-group-form/static-group-form.schema';
import { usePermission } from '../../contexts/permission.context';

export type StaticGroupFormMode = 'create' | 'edit' | 'view';

export const StaticGroupDeviceUsers: FC = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const navigate = useNavigate();
  const toaster = useToaster();
  const crudRequest = useRequest();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [staticGroup, setStaticGroup] = useState<StaticGroupDeviceUsersFullDto>();
  const [formMode, setFormMode] = useState<StaticGroupFormMode>(id === 'new' ? 'create' : 'view');

  const staticGroupForm = useForm<StaticGroupFormValues>({
    mode: 'onChange',
    resolver: yupResolver(staticGroupFormSchema),
    defaultValues: staticGroupFormSchema.getDefault()
  });
  const {
    getValues,
    reset,
    trigger,
    register,
    formState: { errors }
  } = staticGroupForm;

  const handleStaticGroupCreate = async () => {
    if (crudRequest.loading) return;

    const isValid = await trigger();
    if (!isValid) return;

    try {
      const request: CreateStaticGroupDeviceUsersRequestDto = {
        title: getValues().display_name,
        device_users: getValues().device_users
      };
      const response = (await crudRequest.send(
        createStaticGroup(request),
        1000
      )) as StaticGroupDeviceUsersFullDto;
      setStaticGroup(response);
      reset({
        display_name: response.title,
        device_users: response.device_user_ids
      });

      setFormMode('view');
      navigate(`./../${response.id}`);
      toaster.add({
        name: 'create-static-group-success',
        content: t('static_groups.page.successfully_created', { group: response.title }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toaster.add({
        name: 'create-static-group-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleStaticGroupEditStart = () => {
    setFormMode('edit');
  };

  const handleStaticGroupCancel = () => {
    if (formMode === 'create') {
      navigate('./..');
      return;
    }
    setFormMode('view');
  };

  const handleStaticGroupEdit = async () => {
    if (crudRequest.loading || !staticGroup) return;

    const isValid = await trigger();
    if (!isValid) return;

    const request: UpdateStaticGroupDeviceUsersRequestDto = {
      title: getValues().display_name,
      device_users: getValues().device_users
    };
    try {
      const response = (await crudRequest.send(
        updateStaticGroup(request, staticGroup.id),
        1000
      )) as StaticGroupDeviceUsersFullDto;
      setStaticGroup(response);
      reset({
        display_name: response.title,
        device_users: response.device_user_ids
      });

      setFormMode('view');
      navigate(`./../${response.id}`);
      toaster.add({
        name: 'update-static-group-success',
        content: t('static_groups.page.successfully_updated', { group: response.title }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toaster.add({
        name: 'update-static-group-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleStaticGroupDelete = async () => {
    if (crudRequest.loading || !staticGroup) return;

    try {
      await crudRequest.send(deleteStaticGroup(staticGroup.id), 1000);
      navigate('./..');
      toaster.add({
        name: 'delete-static-group-success',
        content: t('static_groups.page.successfully_deleted', {
          group: staticGroup.title
        }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toaster.add({
        name: 'delete-static-group-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteModalOpen(true);
  };

  const init = async () => {
    if (id && id !== 'new') {
      const response = (await crudRequest.send(
        getStaticGroup(id),
        1000
      )) as StaticGroupDeviceUsersFullDto;
      setStaticGroup(response);
      reset({
        display_name: response.title,
        device_users: response.device_user_ids
      });
    }
  };

  useEffect(() => {
    void init();
  }, [id]);

  return (
    <div className={css.Root}>
      <Header
        breadcrumbsTitle={
          formMode === 'create' ? t('static_groups.page.new_group_title') : staticGroup?.title
        }
        rightContent={
          <ActionMenu
            mode={formMode}
            disabled={crudRequest.loading || isAllowedTo(Permission.EditGroups)}
            onStaticGroupCancel={handleStaticGroupCancel}
            onStaticGroupCreate={handleStaticGroupCreate}
            onStaticGroupDelete={handleDeleteModalOpen}
            onStaticGroupEditStart={handleStaticGroupEditStart}
            onStaticGroupEdit={handleStaticGroupEdit}
          />
        }
      />
      <div className={css.Content}>
        {crudRequest.loading ? (
          <StaticGroupFormSkeleton />
        ) : (
          <>
            <div className={css.Title}>
              {formMode === 'view' && <Text variant="display-2">{staticGroup?.title}</Text>}
              {formMode !== 'view' && (
                <Popover content={t('common.click_to_edit')}>
                  <TextInput
                    placeholder={t('static_groups.page.group_information_tab.display_name')}
                    size="xl"
                    view="clear"
                    error={errors.display_name?.message}
                    errorPlacement="inside"
                    controlProps={{ className: css.TitleInput }}
                    {...register('display_name')}
                  />
                </Popover>
              )}
            </div>
            <StaticGroupForm mode={formMode} form={staticGroupForm} />
          </>
        )}
      </div>
      <Dialog onClose={handleDeleteModalClose} open={isDeleteModalOpen}>
        <Dialog.Header caption={t('static_groups.page.modal_delete.title')} />
        <Dialog.Body>
          <Text>{t('static_groups.page.modal_delete.message', { title: staticGroup?.title })}</Text>
        </Dialog.Body>
        <Dialog.Footer
          textButtonApply={t('static_groups.page.modal_delete.delete_btn')}
          textButtonCancel={t('common.modal.cancel_btn')}
          onClickButtonApply={handleStaticGroupDelete}
          onClickButtonCancel={handleDeleteModalClose}
        />
      </Dialog>
    </div>
  );
};
