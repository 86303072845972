import { FC, FormEvent, useState } from 'react';
import css from './device-users-upload-modal.module.scss';
import { useTranslation } from 'react-i18next';
import { uploadDeviceUsers } from '../../../../../api/device-users';
import { FileInput } from '../../../../components/file-input-v2/file-input.component';
import useRequest from '../../../../../hooks/useRequest';
import { downloadFile } from '../../../../../utils/file.utils';
import { getLocalizedErrorString } from '../../../../../utils/localize-error';
import { Dialog, Link, Text, useToaster } from '@gravity-ui/uikit';

interface IProps {
  isOpen: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

export const DeviceUsersUploadModal: FC<IProps> = (props) => {
  const { isOpen, onSubmit, onCancel } = props;
  const [isProfileUploaded, setIsProfileUploaded] = useState<boolean>(false);
  const [isMergeConflictPending, setIsMergeConflictPending] = useState<boolean>(false);
  const [csvData, setCsvData] = useState<File | undefined>();
  const { t } = useTranslation();
  const uploadRequest = useRequest();
  const toaster = useToaster();

  const handleUploadCSV = (file: File) => {
    setCsvData(file);
  };

  const handleCancel = () => {
    setIsMergeConflictPending(false);
    onCancel();
  };

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
  };

  const handleUpload = async () => {
    if (!csvData || uploadRequest.loading) return;
    try {
      if (isMergeConflictPending) {
        await uploadRequest.send(uploadDeviceUsers({ spreadsheet: csvData, merge: true }), 1000);
      } else {
        await uploadRequest.send(uploadDeviceUsers({ spreadsheet: csvData }), 1000);
      }
      setIsMergeConflictPending(false);
      onSubmit();
      toaster.add({
        name: 'upload-success',
        content: t('device_users.import.successfully_import'),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toaster.add({
        name: 'upload-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleUploadFormChanged = () => {
    setIsProfileUploaded(true);
  };

  const handleDownloadExample = () => {
    const content = [
      'username',
      'username@mail.com',
      'Имя Фамилия',
      '88001234455',
      'Должность'
    ].join(',');
    downloadFile(`${t('device_users.import.import_example_filename')}.csv`, [content]);
  };

  return (
    <Dialog onClose={onCancel} open={isOpen}>
      <Dialog.Header caption={t('device_users.import.import_user_list')} />
      <Dialog.Body>
        <form className={css.Form} onSubmit={handleFormSubmit} onChange={handleUploadFormChanged}>
          <FileInput accept=".csv" onUpdate={handleUploadCSV} />
          {!isMergeConflictPending && (
            <div className={css.Info}>
              <Text>{t('device_users.import.import_user_help_text1')}</Text>
              <Text>{t('device_users.import.import_user_help_text2')}</Text>
              <Link onClick={handleDownloadExample} href="">
                <Text>{t('device_users.import.import_user_help_download')}</Text>
              </Link>
            </div>
          )}
          {isMergeConflictPending && <Text>{t('device_users.import.description_of_merger')}</Text>}
        </form>
      </Dialog.Body>
      <Dialog.Footer
        textButtonApply={t('common.modal.default_submit_btn')}
        textButtonCancel={t('common.modal.cancel_btn')}
        onClickButtonApply={handleUpload}
        onClickButtonCancel={handleCancel}
        loading={uploadRequest.loading}
        renderButtons={(buttonApply, buttonCancel) => (
          <>
            {buttonCancel}
            {isProfileUploaded && buttonApply}
          </>
        )}
      />
    </Dialog>
  );
};
