import css from './commands-tab.module.scss';
import React, { FC, FormEvent, useEffect, useState } from 'react';
import {
  CommandDto,
  CommandStatus,
  DeviceFullDto,
  InstallActionType,
  Permission
} from '../../../../../../../../../types/api';
import {
  clearPasscode,
  disableBluetooth,
  disableLostMode,
  disableRemoteDesktop,
  enableBluetooth,
  enableLostMode,
  enableRemoteDesktop,
  getCommands,
  lockDevice,
  lockMobileDevice,
  performOsUpdateAction,
  renewMdmProfile,
  sendBlankPush,
  setFirmwarePassword,
  setRecoveryLock,
  unrollDevice,
  updateInventory,
  wipeDevice
} from '../../../../../../../../../api/device';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  enableLostModeFormSchema,
  EnableLostModeGeneralForm,
  lockDeviceFormSchema,
  LockDeviceGeneralForm,
  lockMobileDeviceFormSchema,
  LockMobileDeviceGeneralForm,
  PendingCommand,
  pendingCommandTitles,
  setFirmwarePasswordFormSchema,
  SetFirmwarePasswordGeneralForm,
  setRecoveryLockFormSchema,
  SetRecoveryLockGeneralForm,
  wipeDeviceFormSchema,
  WipeDeviceGeneralForm
} from './commands-tab.schema';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../../../../../../../utils/time.utils';
import { commandRequestTypes } from '../../../../../../../../../const/commands.const';
import { getLocalizedErrorString } from '../../../../../../../../../utils/localize-error';
import { usePermission } from '../../../../../../../../contexts/permission.context';
import useDeviceSection, {
  DeviceType
} from '../../../../../../../../contexts/device-section.context';
import { BlankPage } from '../../../../../../../../components/blank-page/blank-page.component';
import { Button, Dialog, Label, Skeleton, Text, TextInput, useToaster } from '@gravity-ui/uikit';
import { Column, Table } from '../../../../../../../../components/table/table.component';

interface ICommandButtonProps {
  onClick: () => void;
  text: string;
  disabled?: boolean;
}

enum CommandSortField {
  Name = 'name',
  Status = 'status',
  IssuedAt = 'issued_at',
  LastPushedAt = 'last_pushed_at'
}

type CommandDtoWithStatus = CommandDto & { status: CommandStatus };

interface IProps {
  device?: DeviceFullDto;
}

export const CommandsTab: FC<IProps> = (props: IProps) => {
  const { device } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isCommandModalLoading, setIsCommandModalLoading] = useState(false);
  const [isCommandModalOpen, setIsCommandModalOpen] = useState(false);
  const [commandModalError, setCommandModalError] = useState('');
  const [pendingCommand, setPendingCommand] = useState<PendingCommand>();
  const [commandsList, setCommandsList] = useState<CommandDtoWithStatus[]>([]);
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const { deviceType } = useDeviceSection();
  const toaster = useToaster();

  const fetchCommandList = async () => {
    if (!device) return;

    setIsLoading(true);
    try {
      const getPendingCommandsList = await getCommands(device?.id, {
        status: CommandStatus.Pending
      });
      const getFailedCommandsList = await getCommands(device?.id, { status: CommandStatus.Failed });

      const pendingCommandsList: CommandDtoWithStatus[] = getPendingCommandsList.commands.map(
        (command) => Object.assign(command, { status: CommandStatus.Pending })
      );
      const failedCommandsList: CommandDtoWithStatus[] = getFailedCommandsList.commands.map(
        (command) => Object.assign(command, { status: CommandStatus.Failed })
      );
      const sortCommandsList: CommandDtoWithStatus[] = [
        ...pendingCommandsList,
        ...failedCommandsList
      ].sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());

      setCommandsList(sortCommandsList);
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'fetch-commands-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  useEffect(() => {
    void fetchCommandList().finally(() => setIsLoading(false));
  }, []);

  const lockDeviceForm = useForm<LockDeviceGeneralForm>({
    mode: 'onChange',
    resolver: yupResolver(lockDeviceFormSchema),
    defaultValues: lockDeviceFormSchema.getDefault()
  });
  const lockRegister = lockDeviceForm.register;
  const lockFormState = lockDeviceForm.formState;

  const lockMobileDeviceForm = useForm<LockMobileDeviceGeneralForm>({
    mode: 'onChange',
    resolver: yupResolver(lockMobileDeviceFormSchema),
    defaultValues: lockMobileDeviceFormSchema.getDefault()
  });
  const lockMobileRegister = lockMobileDeviceForm.register;
  const lockMobileFormState = lockMobileDeviceForm.formState;

  const wipeDeviceForm = useForm<WipeDeviceGeneralForm>({
    mode: 'onChange',
    resolver: yupResolver(wipeDeviceFormSchema),
    defaultValues: wipeDeviceFormSchema.getDefault()
  });
  const wipeRegister = wipeDeviceForm.register;
  const wipeFormState = wipeDeviceForm.formState;

  const setFirmwarePasswordForm = useForm<SetFirmwarePasswordGeneralForm>({
    mode: 'onChange',
    resolver: yupResolver(setFirmwarePasswordFormSchema),
    defaultValues: setFirmwarePasswordFormSchema.getDefault()
  });
  const setFirmwarePasswordRegister = setFirmwarePasswordForm.register;
  const setFirmwarePasswordFormState = setFirmwarePasswordForm.formState;

  const setRecoveryLockForm = useForm<SetRecoveryLockGeneralForm>({
    mode: 'onChange',
    resolver: yupResolver(setRecoveryLockFormSchema),
    defaultValues: setRecoveryLockFormSchema.getDefault()
  });
  const SetRecoveryLockRegister = setRecoveryLockForm.register;
  const setRecoveryLockFormState = setRecoveryLockForm.formState;

  const enableLostModeForm = useForm<EnableLostModeGeneralForm>({
    mode: 'onChange',
    resolver: yupResolver(enableLostModeFormSchema),
    defaultValues: enableLostModeFormSchema.getDefault()
  });
  const EnableLostModeRegister = enableLostModeForm.register;
  const enableLostModeFormState = enableLostModeForm.formState;

  const handleLockClick = () => {
    setPendingCommand('LockDevice');
    setIsCommandModalOpen(true);
  };
  const handleWipeClick = () => {
    setPendingCommand('EraseDevice');
    setIsCommandModalOpen(true);
  };
  const handleRenewMdmProfileClick = () => {
    setPendingCommand('RenewMDMProfile');
    setIsCommandModalOpen(true);
  };
  const handleRemoveMdmProfileClick = () => {
    setPendingCommand('RemoveMDMProfile');
    setIsCommandModalOpen(true);
  };
  const handleEnableBluetoothClick = () => {
    setPendingCommand('EnableBluetooth');
    setIsCommandModalOpen(true);
  };
  const handleDisableBluetoothClick = () => {
    setPendingCommand('DisableBluetooth');
    setIsCommandModalOpen(true);
  };
  const handleDownloadUpdatesClick = () => {
    setPendingCommand('DownloadUpdates');
    setIsCommandModalOpen(true);
  };
  const handleInstallUpdatesClick = () => {
    setPendingCommand('InstallUpdates');
    setIsCommandModalOpen(true);
  };
  const handleEnableRemoteDesktopClick = () => {
    setPendingCommand('EnableRemoteDesktop');
    setIsCommandModalOpen(true);
  };
  const handleDisableRemoteDesktopClick = () => {
    setPendingCommand('DisableRemoteDesktop');
    setIsCommandModalOpen(true);
  };

  const handleBlankPushClick = () => {
    setPendingCommand('BlankPush');
    setIsCommandModalOpen(true);
  };

  const handleSetFirmwarePasswordClick = () => {
    setPendingCommand('SetFirmwarePassword');
    setIsCommandModalOpen(true);
  };

  const handleSetRecoveryLockClick = () => {
    setPendingCommand('SetRecoveryLock');
    setIsCommandModalOpen(true);
  };
  const handleUpdateInventoryClick = () => {
    setPendingCommand('UpdateInventory');
    setIsCommandModalOpen(true);
  };
  const handleClearPasscodeClick = () => {
    setPendingCommand('ClearPasscode');
    setIsCommandModalOpen(true);
  };
  const handleEnableLostMode = () => {
    setPendingCommand('EnableLostMode');
    setIsCommandModalOpen(true);
  };
  const handleDisableLostMode = () => {
    setPendingCommand('DisableLostMode');
    setIsCommandModalOpen(true);
  };

  const handleModalClose = () => {
    setIsCommandModalOpen(false);
    setCommandModalError('');
    setIsCommandModalLoading(false);
    lockDeviceForm.reset();
    wipeDeviceForm.reset();
    setRecoveryLockForm.reset();
    setFirmwarePasswordForm.reset();
  };
  const handleModalSubmit = async () => {
    if (isCommandModalLoading) return;

    try {
      switch (pendingCommand) {
        case 'LockDevice': {
          if (deviceType === DeviceType.COMPUTERS) {
            await lockDeviceForm.trigger();
            const errorKeys = Object.keys(lockDeviceForm.formState.errors);
            if (errorKeys.length > 0) return;
            if (device) {
              setIsCommandModalLoading(true);
              await lockDevice(device.id, lockDeviceForm.getValues());
            }
            lockDeviceForm.reset();
          } else {
            await lockMobileDeviceForm.trigger();
            const errorKeys = Object.keys(lockMobileDeviceForm.formState.errors);
            if (errorKeys.length > 0) return;
            if (device) {
              setIsCommandModalLoading(true);
              await lockMobileDevice(device.id, lockMobileDeviceForm.getValues());
            }
            lockMobileDeviceForm.reset();
          }

          break;
        }
        case 'RemoveMDMProfile': {
          if (device) {
            setIsCommandModalLoading(true);
            await unrollDevice(device.id);
          }
          break;
        }
        case 'RenewMDMProfile': {
          if (device) {
            setIsCommandModalLoading(true);
            await renewMdmProfile(device.id);
          }
          break;
        }
        case 'EraseDevice': {
          if (device) {
            setIsCommandModalLoading(true);
            if (deviceType === DeviceType.COMPUTERS) {
              const formValues = wipeDeviceForm.getValues();
              if (formValues.pin) await wipeDevice(device.id, wipeDeviceForm.getValues());
              else {
                setCommandModalError(
                  t(
                    'inventory.device_page.management_tab.commands_tab.commands_section.modals.wipe.errors.pin_required'
                  )
                );
                setIsCommandModalLoading(false);
                return;
              }
            }
            if (deviceType === DeviceType.MOBILE) await wipeDevice(device.id);
          }
          break;
        }
        case 'EnableBluetooth': {
          if (device) {
            setIsCommandModalLoading(true);
            await enableBluetooth(device.id);
          }
          break;
        }
        case 'DisableBluetooth': {
          if (device) {
            setIsCommandModalLoading(true);
            await disableBluetooth(device.id);
          }
          break;
        }
        case 'BlankPush': {
          if (device) {
            setIsCommandModalLoading(true);
            await sendBlankPush(device.id);
          }
          break;
        }
        case 'InstallUpdates': {
          if (device) {
            setIsCommandModalLoading(true);
            await performOsUpdateAction(device.id, {
              install_action: InstallActionType.InstallASAP,
              product_key: ''
            });
          }
          break;
        }
        case 'DownloadUpdates': {
          if (device) {
            setIsCommandModalLoading(true);
            await performOsUpdateAction(device.id, {
              install_action: InstallActionType.DownloadOnly,
              product_key: ''
            });
          }
          break;
        }
        case 'EnableRemoteDesktop': {
          if (device) {
            setIsCommandModalLoading(true);
            await enableRemoteDesktop(device.id);
          }
          break;
        }
        case 'DisableRemoteDesktop': {
          if (device) {
            setIsCommandModalLoading(true);
            await disableRemoteDesktop(device.id);
          }
          break;
        }
        case 'SetFirmwarePassword': {
          await setFirmwarePasswordForm.trigger();
          const errorKeys = Object.keys(setFirmwarePasswordForm.formState.errors);
          if (errorKeys.length > 0) return;
          if (device) {
            setIsCommandModalLoading(true);
            const result = await setFirmwarePassword(
              device.id,
              setFirmwarePasswordForm.getValues()
            );
            if (result.error) {
              setIsCommandModalLoading(false);
              setCommandModalError(result.error);
              return;
            }
          }
          setFirmwarePasswordForm.reset();
          break;
        }
        case 'SetRecoveryLock': {
          await setRecoveryLockForm.trigger();
          const errorKeys = Object.keys(setRecoveryLockForm.formState.errors);
          if (errorKeys.length > 0) return;
          if (device) {
            setIsCommandModalLoading(true);
            const result = await setRecoveryLock(device.id, setRecoveryLockForm.getValues());
            if (result.error) {
              setIsCommandModalLoading(false);
              setCommandModalError(result.error);
              return;
            }
          }
          setRecoveryLockForm.reset();
          break;
        }
        case 'UpdateInventory': {
          if (device) {
            setIsCommandModalLoading(true);
            await updateInventory(device.id);
          }
          break;
        }
        case 'ClearPasscode': {
          if (device) {
            setIsCommandModalLoading(true);
            const response = await clearPasscode(device.id);
            if (response.error) {
              setCommandModalError(response.error);
              setIsCommandModalLoading(false);
              return;
            }
          }
          break;
        }
        case 'EnableLostMode': {
          await enableLostModeForm.trigger();
          setCommandModalError('');
          const errorKeys = Object.keys(enableLostModeForm.formState.errors);
          if (errorKeys.length > 0) return;
          const formValues = enableLostModeForm.getValues();
          if (
            (!formValues.message || formValues.message === '') &&
            (!formValues.phone_number || formValues.phone_number === '')
          ) {
            setIsCommandModalLoading(false);
            setCommandModalError(
              t(
                'inventory_mobile.device_page.management_tab.commands_tab.commands_section.modals.enable_lost_mode.errors.message_or_phone_number_is_required'
              )
            );
            return;
          }
          if (device) {
            setIsCommandModalLoading(true);
            const result = await enableLostMode(device.id, enableLostModeForm.getValues());
            if (result.error) {
              setIsCommandModalLoading(false);
              setCommandModalError(result.error);
              return;
            }
          }
          setRecoveryLockForm.reset();
          break;
        }
        case 'DisableLostMode': {
          if (device) {
            setIsCommandModalLoading(true);
            await disableLostMode(device.id);
          }
          break;
        }
      }
      setIsCommandModalOpen(false);
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      setCommandModalError(localizedErrorString);
    }
    setIsCommandModalLoading(false);
  };

  const randomizePassword = () => {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const symbols = '~`! @#$%^&*()_-+={[}]|:;"\'<,>.?/';
    const passwordLength = 11;
    let randomPassword = '';
    for (let i = 0, n = charset.length; i < passwordLength; ++i) {
      randomPassword += charset.charAt(Math.floor(Math.random() * n));
    }
    randomPassword += symbols.charAt(Math.floor(Math.random() * symbols.length));
    return randomPassword;
  };

  const handleClickRandomizeFirmwarePassword = () => {
    const randomPassword = randomizePassword();
    setFirmwarePasswordForm.setValue('new_password', randomPassword);
  };

  const handleClickRandomizeRecoveryLock = () => {
    const randomPassword = randomizePassword();
    setRecoveryLockForm.setValue('new_password', randomPassword);
  };

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
  };

  const computerButtons: ICommandButtonProps[] = [
    {
      onClick: handleLockClick,
      text: t('inventory.device_page.management_tab.commands_tab.commands_section.commands.lock'),
      disabled: !isAllowedTo(Permission.EditCommands)
    },
    {
      onClick: handleRenewMdmProfileClick,
      text: t(
        'inventory.device_page.management_tab.commands_tab.commands_section.commands.renew_mdm_profile'
      ),
      disabled: !isAllowedTo(Permission.EditCommands)
    },
    {
      onClick: handleRemoveMdmProfileClick,
      text: t(
        'inventory.device_page.management_tab.commands_tab.commands_section.commands.remove_mdm_profile'
      ),
      disabled: !isAllowedTo(Permission.EditCommands)
    },
    {
      onClick: handleWipeClick,
      text: t('inventory.device_page.management_tab.commands_tab.commands_section.commands.wipe'),
      disabled: !isAllowedTo(Permission.EditCommands)
    },
    {
      onClick: handleInstallUpdatesClick,
      text: t(
        'inventory.device_page.management_tab.commands_tab.commands_section.commands.install_updates'
      ),
      disabled: !isAllowedTo(Permission.EditCommands)
    },
    {
      onClick: handleDownloadUpdatesClick,
      text: t(
        'inventory.device_page.management_tab.commands_tab.commands_section.commands.download_updates'
      ),
      disabled: !isAllowedTo(Permission.EditCommands)
    },
    {
      onClick: handleEnableBluetoothClick,
      text: t(
        'inventory.device_page.management_tab.commands_tab.commands_section.commands.enable_bluetooth'
      ),
      disabled: !isAllowedTo(Permission.EditCommands)
    },
    {
      onClick: handleEnableRemoteDesktopClick,
      text: t(
        'inventory.device_page.management_tab.commands_tab.commands_section.commands.enable_ard'
      ),
      disabled: !isAllowedTo(Permission.EditCommands)
    },
    {
      onClick: handleBlankPushClick,
      text: t(
        'inventory.device_page.management_tab.commands_tab.commands_section.commands.send_blank_push'
      ),
      disabled: !isAllowedTo(Permission.EditCommands)
    },
    {
      onClick: handleDisableBluetoothClick,
      text: t(
        'inventory.device_page.management_tab.commands_tab.commands_section.commands.disable_bluetooth'
      ),
      disabled: !isAllowedTo(Permission.EditCommands)
    },
    {
      onClick: handleDisableRemoteDesktopClick,
      text: t(
        'inventory.device_page.management_tab.commands_tab.commands_section.commands.disable_ard'
      ),
      disabled: !isAllowedTo(Permission.EditCommands)
    }
  ];

  if (device?.device_information?.is_apple_silicon) {
    computerButtons.push({
      onClick: handleSetRecoveryLockClick,
      text: t(
        'inventory.device_page.management_tab.commands_tab.commands_section.commands.set_recovery_lock'
      ),
      disabled: !isAllowedTo(Permission.EditCommands)
    });
  } else {
    computerButtons.push({
      onClick: handleSetFirmwarePasswordClick,
      text: t(
        'inventory.device_page.management_tab.commands_tab.commands_section.commands.set_firmware_password'
      ),
      disabled: !isAllowedTo(Permission.EditCommands)
    });
  }

  const mobileButtons: ICommandButtonProps[] = [
    {
      onClick: handleLockClick,
      text: t(
        'inventory_mobile.device_page.management_tab.commands_tab.commands_section.commands.lock'
      ),
      disabled: !isAllowedTo(Permission.EditCommands)
    },
    {
      onClick: handleRenewMdmProfileClick,
      text: t(
        'inventory_mobile.device_page.management_tab.commands_tab.commands_section.commands.renew_mdm_profile'
      ),
      disabled: !isAllowedTo(Permission.EditCommands)
    },
    {
      onClick: handleRemoveMdmProfileClick,
      text: t(
        'inventory_mobile.device_page.management_tab.commands_tab.commands_section.commands.unmanage_device'
      ),
      disabled: !isAllowedTo(Permission.EditCommands)
    },
    {
      onClick: handleWipeClick,
      text: t(
        'inventory_mobile.device_page.management_tab.commands_tab.commands_section.commands.wipe'
      ),
      disabled: !isAllowedTo(Permission.EditCommands)
    },
    {
      onClick: handleUpdateInventoryClick,
      text: t(
        'inventory_mobile.device_page.management_tab.commands_tab.commands_section.commands.update_inventory'
      ),
      disabled: !isAllowedTo(Permission.EditCommands)
    },
    {
      onClick: handleClearPasscodeClick,
      text: t(
        'inventory_mobile.device_page.management_tab.commands_tab.commands_section.commands.clear_passcode'
      ),
      disabled: !isAllowedTo(Permission.EditCommands)
    },
    {
      onClick: handleBlankPushClick,
      text: t(
        'inventory_mobile.device_page.management_tab.commands_tab.commands_section.commands.send_blank_push'
      ),
      disabled: !isAllowedTo(Permission.EditCommands)
    },
    {
      onClick: handleEnableLostMode,
      text: t(
        'inventory_mobile.device_page.management_tab.commands_tab.commands_section.commands.enable_lost_mode'
      ),
      disabled: !isAllowedTo(Permission.EditCommands)
    },
    {
      onClick: handleDisableLostMode,
      text: t(
        'inventory_mobile.device_page.management_tab.commands_tab.commands_section.commands.disable_lost_mode'
      ),
      disabled: !isAllowedTo(Permission.EditCommands)
    }
  ];
  const actualButtons: ICommandButtonProps[] =
    deviceType === DeviceType.COMPUTERS ? computerButtons : mobileButtons;

  const columns: Column<CommandDtoWithStatus>[] = [
    {
      id: CommandSortField.Name,
      name: t(
        'inventory.device_page.management_tab.commands_tab.pending_commands_section.table.heading.name'
      ),
      selector: (command) => commandRequestTypes[command.command_type]
    },
    {
      id: CommandSortField.Status,
      name: t(
        'inventory.device_page.management_tab.commands_tab.pending_commands_section.table.heading.status'
      ),
      selector: (command) => {
        switch (command.status) {
          case CommandStatus.Pending:
            return (
              <Label theme="info">
                {t(
                  'inventory.device_page.management_tab.commands_tab.pending_commands_section.table.status.pending'
                )}
              </Label>
            );
          case CommandStatus.Failed:
            return (
              <Label theme="unknown">
                {t(
                  'inventory.device_page.management_tab.commands_tab.pending_commands_section.table.status.failed'
                )}
              </Label>
            );
        }
      }
    },
    {
      id: CommandSortField.IssuedAt,
      name: t(
        'inventory.device_page.management_tab.commands_tab.pending_commands_section.table.heading.issued'
      ),
      selector: (command) => formatDate(command.created_at)
    },
    {
      id: CommandSortField.LastPushedAt,
      name: t(
        'inventory.device_page.management_tab.commands_tab.pending_commands_section.table.heading.last_push'
      ),
      selector: (command) => formatDate(command.updated_at)
    }
  ];

  return device?.mdm_enrollment_status ? (
    <>
      <form onSubmit={handleFormSubmit}>
        <fieldset className={css.Fieldset} disabled={!isAllowedTo(Permission.EditCommands)}>
          <div className={css.ManagementCommandsGrid}>
            {actualButtons.map((button, index) => (
              <Button
                key={index}
                onClick={button.onClick}
                disabled={button?.disabled}
                view="outlined"
                size="xl"
              >
                {button.text}
              </Button>
            ))}
          </div>
          {isLoading ? (
            <Skeleton style={{ width: '100%', height: 500 }} />
          ) : (
            <Table columns={columns} data={commandsList} />
          )}
          <Dialog onClose={handleModalClose} open={isCommandModalOpen}>
            <Dialog.Header caption={pendingCommand ? pendingCommandTitles[pendingCommand] : ''} />
            <Dialog.Body>
              {pendingCommand === 'RemoveMDMProfile' &&
                t(
                  'inventory.device_page.management_tab.commands_tab.commands_section.modals.remove_mdm_profile.message'
                )}
              {pendingCommand === 'RenewMDMProfile' &&
                t(
                  'inventory.device_page.management_tab.commands_tab.commands_section.modals.renew_mdm_profile.message'
                )}
              {pendingCommand === 'EnableBluetooth' &&
                t(
                  'inventory.device_page.management_tab.commands_tab.commands_section.modals.enable_bluetooth.message'
                )}
              {pendingCommand === 'DisableBluetooth' &&
                t(
                  'inventory.device_page.management_tab.commands_tab.commands_section.modals.disable_bluetooth.message'
                )}
              {pendingCommand === 'BlankPush' &&
                t(
                  'inventory.device_page.management_tab.commands_tab.commands_section.modals.send_blank_push.message'
                )}
              {pendingCommand === 'LockDevice' && deviceType === DeviceType.COMPUTERS && (
                <form className={css.CommandForm}>
                  <div>
                    <Text variant="subheader-1">
                      {t(
                        'inventory.device_page.management_tab.commands_tab.commands_section.modals.lock.field_titles.message'
                      )}
                    </Text>
                    <TextInput
                      type="text"
                      error={lockFormState.errors.message?.message}
                      {...lockRegister('message')}
                    />
                  </div>
                  <div>
                    <Text variant="subheader-1">
                      {t(
                        'inventory.device_page.management_tab.commands_tab.commands_section.modals.lock.field_titles.pin'
                      )}
                    </Text>
                    <TextInput
                      type="number"
                      error={lockFormState.errors.pin?.message}
                      {...lockRegister('pin')}
                    />
                  </div>
                  <div>
                    <Text variant="subheader-1">
                      {t(
                        'inventory.device_page.management_tab.commands_tab.commands_section.modals.lock.field_titles.phone_number'
                      )}
                    </Text>
                    <TextInput
                      type="tel"
                      error={lockFormState.errors.phone_number?.message}
                      {...lockRegister('phone_number')}
                    />
                  </div>
                </form>
              )}
              {pendingCommand === 'LockDevice' && deviceType === DeviceType.MOBILE && (
                <form className={css.CommandForm}>
                  <div>
                    <Text variant="subheader-1">
                      {t(
                        'inventory.device_page.management_tab.commands_tab.commands_section.modals.lock.field_titles.message'
                      )}
                    </Text>
                    <TextInput
                      type="text"
                      error={lockMobileFormState.errors.message?.message}
                      {...lockMobileRegister('message')}
                    />
                  </div>
                  <div>
                    <Text variant="subheader-1">
                      {t(
                        'inventory.device_page.management_tab.commands_tab.commands_section.modals.lock.field_titles.phone_number'
                      )}
                    </Text>
                    <TextInput
                      type="tel"
                      error={lockMobileFormState.errors.phone_number?.message}
                      {...lockMobileRegister('phone_number')}
                    />
                  </div>
                </form>
              )}
              {pendingCommand === 'EraseDevice' && deviceType === DeviceType.COMPUTERS && (
                <form className={css.CommandForm}>
                  <div>
                    <Text variant="subheader-1">
                      {t(
                        'inventory.device_page.management_tab.commands_tab.commands_section.modals.wipe.field_titles.pin'
                      )}
                    </Text>
                    <TextInput
                      type="number"
                      error={wipeFormState.errors.pin?.message}
                      {...wipeRegister('pin')}
                    />
                  </div>
                </form>
              )}
              {pendingCommand === 'EraseDevice' &&
                deviceType === DeviceType.MOBILE &&
                t(
                  'inventory.device_page.management_tab.commands_tab.commands_section.modals.wipe_ios.message'
                )}
              {pendingCommand === 'DownloadUpdates' &&
                t(
                  'inventory.device_page.management_tab.commands_tab.commands_section.modals.download_updates.message'
                )}
              {pendingCommand === 'InstallUpdates' &&
                t(
                  'inventory.device_page.management_tab.commands_tab.commands_section.modals.install_updates.message'
                )}
              {pendingCommand === 'EnableRemoteDesktop' &&
                t(
                  'inventory.device_page.management_tab.commands_tab.commands_section.modals.enable_ard.message'
                )}
              {pendingCommand === 'DisableRemoteDesktop' &&
                t(
                  'inventory.device_page.management_tab.commands_tab.commands_section.modals.disable_ard.message'
                )}
              {pendingCommand === 'SetFirmwarePassword' && (
                <form className={css.CommandForm}>
                  <div>
                    <Text variant="subheader-1">
                      {t(
                        'inventory.device_page.management_tab.commands_tab.commands_section.modals.set_firmware_password.field_titles.current_password'
                      )}
                    </Text>
                    <TextInput
                      type="text"
                      error={setFirmwarePasswordFormState.errors.current_password?.message}
                      {...setFirmwarePasswordRegister('current_password')}
                    />
                  </div>
                  <div>
                    <Text variant="subheader-1">
                      {t(
                        'inventory.device_page.management_tab.commands_tab.commands_section.modals.set_firmware_password.field_titles.new_password'
                      )}
                    </Text>
                    <TextInput
                      type="text"
                      error={setFirmwarePasswordFormState.errors.new_password?.message}
                      {...setFirmwarePasswordRegister('new_password')}
                    />
                  </div>
                  <Button view="action" onClick={handleClickRandomizeFirmwarePassword}>
                    {t(
                      'inventory.device_page.management_tab.commands_tab.commands_section.modals.set_recovery_lock.randomize_btn'
                    )}
                  </Button>
                </form>
              )}
              {pendingCommand === 'SetRecoveryLock' && (
                <form className={css.CommandForm}>
                  <div>
                    <Text variant="subheader-1">
                      {t(
                        'inventory.device_page.management_tab.commands_tab.commands_section.modals.set_recovery_lock.field_titles.current_password'
                      )}
                    </Text>
                    <TextInput
                      type="text"
                      error={setRecoveryLockFormState.errors.current_password?.message}
                      {...SetRecoveryLockRegister('current_password')}
                    />
                  </div>
                  <div>
                    <Text variant="subheader-1">
                      {t(
                        'inventory.device_page.management_tab.commands_tab.commands_section.modals.set_recovery_lock.field_titles.new_password'
                      )}
                    </Text>
                    <TextInput
                      type="text"
                      error={setRecoveryLockFormState.errors.new_password?.message}
                      {...SetRecoveryLockRegister('new_password')}
                    />
                  </div>
                  <Button view="action" onClick={handleClickRandomizeRecoveryLock}>
                    {t(
                      'inventory.device_page.management_tab.commands_tab.commands_section.modals.set_recovery_lock.randomize_btn'
                    )}
                  </Button>
                </form>
              )}
              {pendingCommand === 'UpdateInventory' &&
                t(
                  'inventory.device_page.management_tab.commands_tab.commands_section.modals.update_inventory.message'
                )}
              {pendingCommand === 'ClearPasscode' &&
                t(
                  'inventory.device_page.management_tab.commands_tab.commands_section.modals.clear_passcode.message'
                )}
              {pendingCommand === 'EnableLostMode' && (
                <form className={css.CommandForm}>
                  <div>
                    <Text variant="subheader-1">
                      {t(
                        'inventory_mobile.device_page.management_tab.commands_tab.commands_section.modals.enable_lost_mode.field_titles.message'
                      )}
                    </Text>
                    <TextInput
                      type="text"
                      error={enableLostModeFormState.errors.message?.message}
                      {...EnableLostModeRegister('message')}
                    />
                  </div>
                  <div>
                    <Text variant="subheader-1">
                      {t(
                        'inventory_mobile.device_page.management_tab.commands_tab.commands_section.modals.enable_lost_mode.field_titles.phone_number'
                      )}
                    </Text>
                    <TextInput
                      type="tel"
                      error={enableLostModeFormState.errors.phone_number?.message}
                      {...EnableLostModeRegister('phone_number')}
                    />
                  </div>
                  <div>
                    <Text variant="subheader-1">
                      {t(
                        'inventory_mobile.device_page.management_tab.commands_tab.commands_section.modals.enable_lost_mode.field_titles.footnote'
                      )}
                    </Text>
                    <TextInput
                      type="text"
                      error={enableLostModeFormState.errors.footnote?.message}
                      {...EnableLostModeRegister('footnote')}
                    />
                  </div>
                </form>
              )}
              {pendingCommand === 'DisableLostMode' &&
                t(
                  'inventory_mobile.device_page.management_tab.commands_tab.commands_section.modals.disable_lost_mode.message'
                )}
            </Dialog.Body>
            <Dialog.Footer
              onClickButtonCancel={handleModalClose}
              onClickButtonApply={handleModalSubmit}
              textButtonApply={t(
                'inventory.device_page.management_tab.commands_tab.commands_section.modals.send_command_btn'
              )}
              textButtonCancel={t('common.modal.cancel_btn')}
              errorText={commandModalError}
              loading={isCommandModalLoading}
            />
          </Dialog>
        </fieldset>
      </form>
    </>
  ) : (
    <BlankPage text={t('inventory.device_page.management_tab.commands_tab.device_offline')} />
  );
};
