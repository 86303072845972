import { FC, FormEvent, useState } from 'react';
import css from './smart-group-devices-form.module.scss';
import { CriteriaTab } from './components/criteria-tab/criteria-tab.component';
import { useTranslation } from 'react-i18next';
import { Tabs, TabsItemProps } from '@gravity-ui/uikit';
import { DevicesListTab } from './components/devices-list-tab/devices-list-tab.component';

export enum SmartGroupDevicesPageTabs {
  DEVICES_LIST = 'Devices List',
  CRITERIA = 'Group Information'
}

interface IProps {
  mode: 'view' | 'create' | 'edit';
}

export const SmartGroupDevicesForm: FC<IProps> = (props: IProps) => {
  const { mode = 'view' } = props;

  const [activeTab, setActiveTab] = useState<string>(SmartGroupDevicesPageTabs.DEVICES_LIST);
  const { t } = useTranslation();

  const handleChangeTab = (tab: string) => setActiveTab(tab);

  const handleFormSubmit = (event: FormEvent) => event.preventDefault();

  const tabs: TabsItemProps[] = [
    {
      id: SmartGroupDevicesPageTabs.DEVICES_LIST,
      title: t('smart_groups.page.devices_list_group_tab.name')
    },
    {
      id: SmartGroupDevicesPageTabs.CRITERIA,
      title: t('smart_groups.page.criteria_tab.name')
    }
  ];

  return (
    <form className={css.Form} onSubmit={handleFormSubmit}>
      <Tabs
        items={tabs}
        activeTab={activeTab}
        onSelectTab={handleChangeTab}
        className={css.NavTabBar}
      />
      {activeTab === SmartGroupDevicesPageTabs.DEVICES_LIST && <DevicesListTab />}
      {activeTab === SmartGroupDevicesPageTabs.CRITERIA && <CriteriaTab mode={mode} />}
    </form>
  );
};
