import { t } from 'i18next';
import { object, string } from 'yup';
import { notEmpty } from '../../../../../../utils/yup.validations';
import i18next from '../../../../../../i18n/i18n';

export interface UserLocalFormValues {
  username: string;
  display_name?: string;
  role: {
    id: string;
    title: string;
  };
  password: string;
  confirmPassword: string;
}

export const userLocalFormSchema = object({
  username: string()
    .required(t('settings.tiles.users.page.user_page.username_required'))
    .test(notEmpty(i18next.t('settings.tiles.users.page.user_page.username_required'))),
  display_name: string(),
  role: object({
    id: string(),
    title: string()
  }),
  password: string()
    .required(t('settings.tiles.users.page.user_page.password_required'))
    .transform((x) => (x === '' ? undefined : x))
    .min(6, t('settings.tiles.users.page.user_page.password_must_be_at_least_6_characters')),
  confirmPassword: string()
    .transform((x) => (x === '' ? undefined : x))
    .test(
      'passwords-match',
      t('settings.tiles.users.page.user_page.passwords_must_match'),
      function (value) {
        return this.parent.password === value;
      }
    )
}).required();
